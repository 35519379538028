import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Link } from '../components/Link';
import { Main } from '../components/Main';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import resumePdf from '../docs/mu-ramadan-cv.pdf';
import { Cyberbtn } from '../components/Cyberbtn';
import { rgba } from 'polished';
import QRcode from '../images/qr-code.svg';
import {styles } from '../layouts/pages/pages.styles';
// import {Qrcode} from "../components/Qrcode"







class resume extends React.Component {
  onLinkStart = (event, { isInternal }) => {
    if (isInternal) {
      this.secuenceElement.exit();
    }
  };
  
  static propTypes = {
    classes: PropTypes.object
  };


  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <header>
            <h1><Text>Download resume</Text></h1>
          </header>
          
          <Fader className={classes.dataContainer}>
             
          <div className={`${classes.videoContainer} ${classes.noClipPath} ${classes.noBackground}`}>

                  <div className={ `${classes.video} ${classes.noClipPath} ${classes.noBackground}`}>

                  <img className={classes.qrImg} src={QRcode} alt="Qr-Code"  />
                  {/* <Qrcode
                    className={classes.brand}
                    onLinkStart={this.onLinkStart}
                  /> */}

                  </div>

                </div>

                <div className={classes.data}>
                <h3><Text>SHORT STORY</Text></h3>
                <Text>
                In the vibrant heart of the digital universe, I am a Full Stack Web Developer with a passion for AI, programming, and cybersecurity. My world is where code meets creativity, where every line is a step towards pioneering new tech horizons. Amidst the neon echoes of cyberspace, I craft digital experiences that transcend the ordinary. Join me in this quest to shape the future, one pixel at a time.
                </Text>
                <div className={classes.downloadBtn}>
                  <Link href={resumePdf}   target='resume'>
                    <Cyberbtn>
                      Download _
                    </Cyberbtn>
                  </Link> 
                </div>

                </div>

            </Fader>

            
          <p className={classes.seeMore}>
          <Text>See more at</Text>
            {' '}
            <Link href='https://memory-alpha.fandom.com/wiki/Subspace_communication' target='pixnt'><Text>Memory Alpha</Text></Link>
          </p>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(resume);
